"use strict";

import Vue from "vue";
import Vuex from "vuex";
import qs from "qs";
import axios from "axios";
import cookies from "vue-cookie";
import auth from "./auth.js";
import address from "./address.js";
import banner from "./banner.js";
import cart from "./cart.js";
import order from "./order.js";
import lkmenu from "./lkmenu.js";
import personal from "./personal.js";
import restaurant from "./restaurant.js";
import map from "./map.js";
import chat from "./chat.js";
import ui from "./ui";
import cache from "./cache";
import router from "../router";
import {convertTextToDate} from "@/helpers";
import {notification} from "@/utils";

axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT;

Vue.use(Vuex);

export default new Vuex.Store({
	modules:
		{
			auth: auth,
			address: address,
			banner: banner,
			chat: chat,
			cart: cart,
			order: order,
			lkmenu,
			personal,
			restaurant,
			map,

			ui,
			cache,
		},
	state:
		{
			ymInitiialized: false,
			closedRestaurantData: {},
			currentCityRestIsWork: true,
			currentCity: false,
			currentCityId: false,
			currentCityPhone: false,
			currentCityImg: false,
			currentCityTime: false,
			currentCityLat: false,
			currentCityLon: false,
			currentCityPadezh: false,
			currentCityWorkTime: false,
			currentRestarauntId: false,
			cities: [],
			restaraunts: [],
			mobMenuState: false,
			user: "",
			currentOwnerRestaurant: false,
			allCurrentOwnerRestaurants: false,
			authState: false,
			currentDate: "",
			activeHash: false,
			cartTimeRange: "",
			cartMeals: [],
			cartLanches: [],
			cartSumm: 0,
			cartBalls: 0,
			cartKkal: 0,
			cartId: null,
			cartProductsCount: 0,
			cartTotalWeight: 0,
			cartDeliverySum: 0,
			businessMeal: false,
			businessMealType: "",
			meals: [],
			menuCategories: [],
			menuProductsCount: [],
			hideDate: false,
			mealByGroups: [],
			lastWindowScrollY: 0,
			cartGifts: false,
			maxPersonsCount: 0,
			done: false,
			showCollective: false,
			mealByGroupsCounts:
				{
					today: true,
					tomorrow: true,
				},
			timerForReloadOnDayEnd: false,
			phoneAfterRegister: false,
			authActiveForm: "",
			cartLoading: true,
			menuLoading: true,
			openPopupName: null,
			currentMealIdInRestLk: null,
			publicPath: process.env.BASE_URL,

			basketPayer: "creator",
			timeRange: "",
			timeRangesWithMenu: [],
			myCartDetails: {},
		},
	getters:
		{
			getYmInitiialized(state)
			{
				return state.ymInitiialized
			},
			getCartTimeRange(state)
			{
				return state.cartTimeRange
			},
			getTimeRange(state)
			{
				return state.timeRange;
			},
			getMenuProductsCount(state)
			{
				return state.menuProductsCount
			},
			getShowCollective(state)
			{
				return state.showCollective
			},
			//Получение информации о моей корзине (колличенство моих блюд, веса, цены и колличества)
			getMyCartDetails(state)
			{
				return state.myCartDetails
			},
			//Получение колличества блюд в текущей корзине
			getCartProductsCount(state)
			{
				return state.cartProductsCount
			},
			getCartSumm(state)
			{
				return state.cartSumm
			},
			getCartKkal(state)
			{
				return state.cartKkal
			},
			getCartBalls(state)
			{
				return state.cartBalls
			},
			getCartTotalWeight(state)
			{
				return state.cartTotalWeight
			},
			getCartDeliverySum(state)
			{
				return state.cartDeliverySum
			},
			getMaxPersonsCount(state)
			{
				return state.maxPersonsCount
			},
			getMenuLoading(state)
			{
				return state.menuLoading
			},
			getCartLoading(state)
			{
				return state.cartLoading
			},
			getTimeRangesWithMenu(state)
			{
				return state.timeRangesWithMenu;
			},
			getCurrentCity(state)
			{
				return state.currentCity;
			},
			getRestarauntId(state)
			{
				return state.currentRestarauntId;
			},
			getCartMeals(state)
			{
				return state.cartMeals;
			},
			getOpenPopupName(state)
			{
				return state.openPopupName;
			},
			getRestaurants(state)
			{
				return state.restaraunts;
			},
			getUser(state)
			{
				return state.user;
			},
			cartProductsAmount(state)
			{
				return state.cartMeals.length + state.cartGifts.length + state.cartLanches.length;
			},
			getMenuCats(state)
			{
				return state.menuCategories;
			},
			cartSumWithBoxes(state, getters)
			{
				let price = state.cartSumm;

				for (let lunch of state.cartLanches)
					price += lunch.count * getters.getLunchBoxesPrice(lunch);

				return price;
			},
			getMeals(state)
			{
				return state.meals;
			},
			getLanches(state)
			{
				return state.cartLanches;
			},
			getDone(state)
			{
				return state.done;
			},
			getCartGiftes(state)
			{
				return state.cartGifts;
			},
			/*
			 * получить цену коробок, которые есть в ланче
			 */
			getLunchBoxesPrice()
			{
				return (lunch) =>
				{
					let price = 0;

					if (lunch.lanches)
						for (let innerLunch of lunch.lanches)
							for (let meal of innerLunch.meals)
							{
								let mealTypes = JSON.stringify(meal.type);
								if (mealTypes.indexOf("box") >= 0)
									price += parseFloat(meal.bx_price);
							}
					else
						for (let meal of lunch.meals)
						{
							let mealTypes = JSON.stringify(meal.type);
							if (mealTypes.indexOf("box") >= 0)
								price += parseFloat(meal.bx_price);
						}

					return price;
				};
			},
			/**
			 * Если в сторе что-то есть и дата = завтра, то 'tomorrow'
			 * Во всех других случая 'today'
			 */
			currentDayStr(state)
			{
				const tomorrowDate = new Date();
				tomorrowDate.setDate(tomorrowDate.getDate() + 1);

				if (tomorrowDate.toLocaleDateString("ru") === state.currentDate)
					return "tomorrow";

				return "today";
			},
			/**
			 * Возвращает id текущей корзины, не путать с cartTypeId
			 */
			getCartId(state)
			{
				return state.cartId;
			},
			getCities(state)
			{
				return state.cities;
			},
			currentCityId(state)
			{
				return state.currentCityId;
			},
		},
	actions:
		{
			//Запрос на получение текущего ресторана по ID
			async fetchCurrentRestaurant({commit, dispatch}, restId)
			{
				try
				{
					const {data} = await axios.get(`/restaurant/getById?restId=${restId}`);

					if (!data.success) return;

					const restaurant = data.restaurant;

					if (!restaurant || !restaurant.address || !restaurant.city || typeof restaurant.city !== "object") return;

					commit("setRestaraunts", [restaurant]);

					if (Object.keys(restaurant.city)[0]) await dispatch("changeCity", {UF_XML_ID: Object.keys(restaurant.city)[0]});

					// Возвращаем адрес ресторана, в виде "город, адрес"
					return `${Object.values(restaurant.city)[0]}, ${restaurant.address}`;
				} catch (error)
				{
					throw new Error(error)
				}
			},
			async getInviteData({commit}, data)
			{
				const params = new URLSearchParams();

				if (data.type === "token")
					params.append("token", data.info);
				else if (data.type === "id")
					params.append("cartTypeId", data.info);

				return await axios.post("/cart/getCartTypeInfo/", params);
			},
			checkAddressFromCookie()
			{
				let adressCookie = cookies.get("address");
				if (adressCookie)
				{
					let adress = adressCookie.split(", ");
					let street = adress[0];
					let house = adress[1];

					if (!house || !street)
					{
						cookies.delete("address");
						console.log("address deleted (store/index.js)", house, street);
					}
				} else
				{
					router.push("/");
					console.log("no adressCookie (store/index.js)", adressCookie);
				}
			},
			async openPopup({commit}, name)
			{
				commit("setOpenPopupName", name);
			},
			/**
			 * Выход
			 */
			async logout({commit})
			{
				try
				{
					const {data} = await axios.get("/auth/logout/");

					if (!data) return;

					if (data.success)
					{
						cookies.delete('basketId');
						localStorage.removeItem("user");
						commit("setUser", "");
						commit("setAuthFormPopup", false);
					}
				} catch (error)
				{
					throw new Error(error);
				}
			},
			/**
			 * Проверка на авторизованность
			 */
			async isLogged({state, store, commit})
			{
				let result = await axios.get("/auth/isLogged/");
				if (!result.data.success)
				{
					localStorage.removeItem("user");
					state.user = "";
					return false;
				}

				commit("setUser", result.data.user);
				return true;
			},
			async getCart({state, commit, dispatch})
			{
				const typeId = cookies.get('basketId') || state.currentBasket?.id;

				const {address, currentDate} = state;

				if (!address) return commit("setCartLoading", false);

				const {restarauntId} = address;

				const restId = restarauntId || cookies.get('restid');

				if (!restId || !currentDate) return commit("setCartLoading", false);

				try
				{
					commit("setCartLoading", true);

					const params = qs.stringify({
						'restId': restId,
						'day': currentDate,
						typeId,
					});

					const {data} = await axios.post('/cart/get/', params);

					if (!data.success) return commit("setCartLoading", false);

					dispatch('fillingCart', data);
				} catch (error)
				{
					throw new Error(error)
				}
			},
			fillingCart({state, commit}, data)
			{
				if (!data) return commit("setCartLoading", false);

				const {summ, balls, weight, count, personsCount, kkal} = data;

				state.cartTimeRange = data?.timeRange;
				state.cartMeals = data?.meals;
				state.cartLanches = data?.lanches;
				state.cartSumm = summ?.totalSumm || 0;
				state.cartBalls = balls?.totalBalls || 0;
				state.cartTotalWeight = weight?.totalWeight || 0;
				state.cartProductsCount = count?.totalCount || 0;
				state.cartKkal = kkal?.totalKkal || 0;
				state.cartGifts = data?.gifts;
				state.cartDeliverySum = data?.delivery;
				state.freeDeliveryFrom = data?.freeDeliveryFrom;
				state.leftForFreeDelivery = data?.leftForFreeDelivery;
				state.maxPersonsCount = data?.maxPersonsCount;
				state.done = data?.done;
				commit('setPersonsCount', personsCount?.totalCount || 0);

				const myCartInfo = {
					summ, balls, weight, count, personsCount, kkal
				}

				commit('setCartId', data?.current_user_cart_id);
				commit('setMyCartDetails', myCartInfo);
				commit("setCartLoading", false);
			},

			/**
			 * Очистка корзины
			 */
			async clearCart({state, dispatch})
			{
				let data = qs.stringify({"cartId": state.cartId});
				let result = await axios.post("/cart/clear/", data);
				dispatch("getCart");
			},

			/**
			 * Достает блюда и ланчи на сегодня
			 * формирует разделы меню
			 * формирует группы меню
			 */
			async getMenu({state, dispatch, commit}, incomingTimeRange)
			{
				const timeRange = incomingTimeRange || state.timeRange || state.cartTimeRange;

				const {address, currentDate, cache} = state;

				if (!address) return;

				const {restarauntId} = address;

				if (!restarauntId || !timeRange) return;

				commit("setMenuLoading", true);

				const menuCacheExists = cache.menu[restarauntId] && cache.menu[restarauntId][currentDate] && cache.menu[restarauntId][currentDate][timeRange];

				let data = {};

				if (menuCacheExists)
				{
					data = cache.menu[restarauntId][currentDate][timeRange];
				} else
				{
					data = await dispatch("cache/updateMenuCache", {
						rest: restarauntId,
						day: currentDate,
						timeRange
					});
				}

				commit("formMealByGroups", data);
			},

			/**
			 * Достает из local storage пользователя
			 */
			remindUser: async (context) =>
			{
				let user = localStorage.getItem("user");
				if (!user) return;
				user = JSON.parse(user);

				context.commit("setUser", user);
			},

			/**
			 * Примнимает день в формате сегодня/завтра
			 * устанавливает значание так-же в куки
			 * после надо заново достать корзину и меню
			 */
			async setDate({dispatch, state}, dayStr)
			{
				if (dayStr === 'today' || dayStr === 'tomorrow')
				{
					state.currentDate = convertTextToDate(dayStr)
				} else
				{
					state.currentDate = dayStr
				}

				cookies.set("day", state.currentDate, 12);

				await dispatch("getMenu");
				await dispatch("getCart");
			},
			async changeCity({commit, state}, incomingCity)
			{
				try
				{
					const params = qs.stringify({"city": incomingCity.UF_XML_ID});
					const {data} = await axios.post("/city/setCity/", params);

					if (!data.success) return false;

					const {city} = data;
					if (!city) return false;

					commit("setCurrentCity",
						{
							"name": city?.UF_NAME,
							"id": city?.UF_XML_ID,
							"phone": city?.UF_PHONE,
							"image": city?.UF_IMAGE_STR,
							"workTime": city?.WORK_TIME,
							"isRestaurantWork": city?.isRestaurantWork,
							"lat": city?.UF_LAT,
							"lon": city?.UF_LON,
							"padezh": city?.UF_PADEZH,
							"time": city?.UF_DELIV_M,
						});

					return true;
				} catch (error)
				{
					throw new Error(error);
				}
			},
			/**
			 * выбор текущего города
			 */
			async selectCity({dispatch, state}, city)
			{
				const result = await dispatch("changeCity", city);

				if (!result) return notification({title: 'При смене города произошла ошибка', type: 'error'});

				cookies.delete("address");
				cookies.delete("coords");
				cookies.delete("restid");
				cookies.delete("basketId");

				if (window.location.href.indexOf(this.publicPath + "order/") !== -1)
					window.location.href = this.publicPath;
				else
					window.location.reload();
			},

			/**
			 * инициализация яндекс метрики
			 */
			async initYandexMetrika({commit, state}, counterId)
			{
				if (state.ymInitiialized) return;

				(function (m, e, t, r, i, k, a)
				{
					m[i] = m[i] || function ()
					{
						(m[i].a = m[i].a || []).push(arguments);
					};
					m[i].l = 1 * new Date();
					k = e.createElement(t), a = e.getElementsByTagName(t)[0], k.async = 1, k.src = r, a.parentNode.insertBefore(k, a);
				})(window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");
				ym(counterId, "init", {
					defer: true,
					clickmap: true,
					trackLinks: true,
					accurateTrackBounce: true,
					webvisor: true,
					ecommerce: "dataLayer",
					userParams: {
						UserID: state.user ? state.user.ID : null
					}
				});

				window.dataLayer = window.dataLayer || [];

				await commit("setYandexMetrikaIsInitiialized");
			},
			async getCurrentCity({commit})
			{
				try
				{
					const {data} = await axios.get("/city/getCurrentCity/");

					if (!data.success) return false;

					commit("setCurrentCity",
						{
							"name": data.city.UF_NAME,
							"id": data.city.UF_XML_ID,
							"phone": data.city.UF_PHONE,
							"image": data.city.UF_IMAGE_STR,
							"workTime": data.city.WORK_TIME,
							"isRestaurantWork": data.city.isRestaurantWork,
							"lat": data.city.UF_LAT,
							"lon": data.city.UF_LON,
							"padezh": data.city.UF_PADEZH,
							"time": data.city.UF_DELIV_M,
						});
					return data.city;
				} catch (error)
				{
					throw new Error(error);
				}
			},
			async getCityList({commit})
			{
				try
				{
					const {data} = await axios.get("/city/getCities/");

					if (!data.success) return [];

					commit('setCities', data.cities);

					return data.cities;
				} catch (error)
				{
					throw new Error(error);
				}
			},
			async changePopUpFromCart({commit})
			{
				commit("setPopUpFromCart");
			},
			async setPayer({commit}, payer)
			{
				commit("setPayer", payer);
			},
		},
	mutations:
		{
			setCities(state, cities)
			{
				state.cities = cities;
			},
			setCurrentDate(state, data)
			{
				state.currentDate = data
			},
			//Устанавливает id текущей корзины, не путать с cartTypeId
			setCartId(state, id)
			{
				state.cartId = id;
			},
			setMyCartDetails(state, {summ, balls, weight, count, personsCount, kkal})
			{
				const id = state.cartId;

				if (!id) return;

				const newDetails = {
					sum: summ?.carts?.[id].summ || 0,
					balls: balls?.carts?.[id].balls || 0,
					weight: weight?.carts?.[id].weight || 0,
					count: count?.carts?.[id].count || 0,
					personsCount: personsCount?.carts?.[id].count || 0,
					kkal: kkal?.carts?.[id].kkal || 0
				}

				state.myCartDetails = newDetails;
			},
			//Добавляет новое блюда в корзину или увеличивает количество блюда, если оно уже есть в корзине
			addMealToCart(state, newMeal)
			{
				const existingMeal = state.cartMeals.find(meal => meal.product_id === newMeal.product_id && meal.user_id === newMeal.user_id);

				if (existingMeal)
				{
					existingMeal.count = newMeal.count;
				} else
				{
					state.cartMeals.push(newMeal);
				}
			},
			//Добавляет новый ланч в корзину или увеличивает колличество ланчей, если он уже есть в корзине
			addLunchToCart(state, newLunch)
			{
				const existingLanch = state.cartLanches.find(lunch => lunch.lanch_id === newLunch.lanch_id && lunch.user_id === newLunch.user_id);

				if (existingLanch)
				{
					existingLanch.count = newLunch.count;
				} else
				{
					state.cartLanches.push(newLunch);
				}
			},
			//Удаляет блюдо из корзины по id
			removeMealFromCart(state, mealId)
			{
				state.cartMeals = state.cartMeals.filter(meal => meal.id !== mealId);
			},
			//Удаляет ланч из корзины по id
			removeLunchFromCart(state, lunchId)
			{
				state.cartLanches = state.cartLanches.filter(lunch => lunch.id !== lunchId);
			},
			//Устанавливает новые данные текущей корзины
			setCartDetails(state, cartInfo)
			{
				const {summ, balls, weight, count, kkal} = cartInfo;

				state.cartSumm = summ.totalSumm || 0;
				state.cartBalls = balls.totalBalls || 0;
				state.cartTotalWeight = weight.totalWeight || 0;
				state.cartProductsCount = count.totalCount || 0;
				state.cartKkal = kkal.totalKkal || 0;

				this.commit('setMyCartDetails', cartInfo);
			},
			setMenuLoading(state, isLoading)
			{
				state.menuLoading = isLoading;
			},
			setCartLoading(state, isLoading)
			{
				state.cartLoading = isLoading;
			},
			clearAllCartItems(state)
			{
				state.cartProductsCount = 0;
				state.cartMeals = [];
				state.cartLanches = [];
			},
			setCurrentCityId(state, code)
			{
				state.currentCityId = code;
			},
			setDone(state, isDone)
			{
				state.done = isDone;
			},
			setPayer(state, payer)
			{
				state.basketPayer = payer;
			},
			setYandexMetrikaIsInitiialized(state)
			{
				state.ymInitiialized = true;
			},
			setPhoneAfterRegister(state, phone)
			{
				state.phoneAfterRegister = phone;
			},
			changeAuthActiveForm(state, newActiveForm)
			{
				state.authActiveForm = newActiveForm;
			},
			/**
			 * Установить название открываемого попапа
			 */
			setOpenPopupName(state, name)
			{
				state.openPopupName = name;
			},
			/**
			 * скрыть мобильное меню
			 */
			hideMobMenu(state)
			{
				if (state.mobMenuState)
				{
					state.mobMenuState = false;
					document.querySelector("#app").classList.remove("page-no-scroll-mob-menu");
					window.scrollTo(0, state.lastWindowScrollY);
				}
			},
			/**
			 * показать мобильное меню
			 */
			showMobMenu(state)
			{
				state.lastWindowScrollY = window.scrollY;
				state.mobMenuState = true;
				document.querySelector("#app").classList.add("page-no-scroll-mob-menu");
			},
			/**
			 * записывает блюда выбранного ресторана
			 */
			setMeals(state, meals)
			{
				state.meals = meals;
			},
			/**
			 ** записывает выбранное бизнес-предложение чтобы достать в конструкторе
			 **/
			choseBusinessMeal(state, businessMeal)
			{
				state.businessMeal = businessMeal;
			},
			/**
			 ** записывает выбранный тип бизнес-предложения (конструктор ланча или ужина)
			 **/
			choseBusinessMealType(state, businessMealType)
			{
				state.businessMealType = businessMealType;
			},
			/**
			 * записывает активный хэш
			 */
			setActiveHash(state, hash)
			{
				state.activeHash = hash;
			},
			/**
			 * записывает название города, id
			 * изображение, телефон
			 * и среднее время доставки
			 */
			setCurrentCity(state, obj)
			{
				state.currentCity = obj.name;
				state.currentCityId = obj.id;
				state.currentCityPhone = obj.phone;
				state.currentCityWorkTime = obj.workTime;
				state.currentCityRestIsWork = obj.isRestaurantWork;
				state.currentCityImg = obj.image;
				state.currentCityTime = obj.time;
				state.currentCityLat = obj.lat;
				state.currentCityLon = obj.lon;
				state.currentCityPadezh = obj.padezh;
			},
			/**
			 * записывает рестораны
			 */
			setRestaraunts(state, restaraunts)
			{
				state.restaraunts = restaraunts;
			},

			/**
			 * записывает ид текущего ресторана
			 */
			async setRestarauntId(state, id)
			{
				if (!id) return;

				cookies.set('restid', id, 12);
				state.currentRestarauntId = id;

				try
				{
					const params = qs.stringify({'restId': id});

					const {data} = await axios.post("/address/showCollective/", params)

					if (data.success) state.showCollective = data.showCollective;
				} catch (error)
				{
					throw new Error(error);
				}
			},
			setUser(state, user)
			{
				localStorage.setItem("user", JSON.stringify(user));
				state.user = user;
			},
			/**
			 * меняет состояние попапа авторизации
			 */
			toggleAuth(state)
			{
				state.authState = !state.authState;
			},
			async formMealByGroups(state, data)
			{
				this.state.timeRangesWithMenu = data.timeRangesWithMenu;
				let menuCategoriesArr = state.menuCategories;

				state.menuProductsCount = data.lanches.length + data.meal.length;

				state.meals = data.meal;

				if (!menuCategoriesArr.length)
				{
					let {data} = await axios.post("/index/getMealTypes/");

					if (data.types && data.types.length)
					{
						menuCategoriesArr = data.types;
						state.menuCategories = data.types;
					}
				}

				let mealByGroups = {};

				if (data.lanches.length)
					mealByGroups["lunches"] = {
						type: "lunches",
						title: "Бизнес-Ланчи",
						foodItems: data.lanches,
					};

				for (let element of data.meal)
				{
					let groupType = element.type.code;
					let groupTitle = element.type.name;
					if (groupType === "second")
					{
						groupType = "hot";
						groupTitle = "Горячее";
					}
					if (!(groupType in mealByGroups))
					{
						mealByGroups[groupType] = {
							type: groupType,
							title: groupTitle,
							foodItems: [],
						};
					}
					mealByGroups[groupType].foodItems.push(element);
				}
				state.mealByGroups = [];
				for (let cat of menuCategoriesArr)
				{
					let group = mealByGroups[cat.type];
					if (group) state.mealByGroups.push(group);
				}

				state.mealByGroups = menuCategoriesArr.map(el => mealByGroups[el.type]).filter(el => el);

				this.commit("setCategories", mealByGroups);

				// чтобы скрывать баннеры, если блюда есть, но еще не подгрузились
				state.mealByGroupsCounts[this.getters.currentDayStr] = !!state.mealByGroups.length;

				state.menuLoading = false;
			},
			setCategories(state, mealByGroups)
			{
				for (let catsElement of state.menuCategories)
					catsElement.show = Object.keys(mealByGroups).length ? (catsElement.type in mealByGroups) : true;
			},

			setCurrentMealIdInRestLk(state, id)
			{
				state.currentMealIdInRestLk = id;
			},
			setCurrentOwnerRestaurant(state, id)
			{
				if (!id)
				{
					localStorage.removeItem("currentOwnerRestaurant");
				} else
				{
					localStorage.setItem("currentOwnerRestaurant", id);
				}
				state.currentOwnerRestaurant = id;
			},
			setAllCurrentOwnerRestaurants(state, restaurants)
			{
				state.allCurrentOwnerRestaurants = restaurants;
			},
			setTimeRange(state, timeRange)
			{
				state.timeRange = timeRange;
			},
		},
});
