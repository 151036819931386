<template>
	<div class="app">
		<notifications
			:position="mobileCheck('width') ? 'bottom center' : 'bottom right'"
			width="364"
		>
			<template
				slot="body"
				slot-scope="props"
			>
				<div class="my-notification">
					<img
						src="~@/assets/img/icons/toast-info.svg"
						alt="Info"
					>
					<div class="my-notification__body">
						<div
							class="my-notification__title"
							v-html="props.item.title"
						/>
						<div v-html="props.item.text"/>
					</div>
					<img
						class="my-notification__close"
						@click="props.close"
						src="~@/assets/img/icons/close.svg"
						alt="Close"
					>
				</div>
			</template>
		</notifications>
		<router-view id="app"/>
	</div>
</template>
<script>
import jivoSite from "./mixins/jivoSite";
import socketEvents from "@/mixins/socketEvents";
import {mobileCheck} from "@/helpers";

export default {
	name: 'App',

	data: () => ({
		pseudoVh: window.document.documentElement.clientHeight / 100,
		iosVh: window.innerHeight / 100,
		isMobile: !!process.env.VUE_APP_MOBILE,
		timeout: null,
	}),

	mixins: [jivoSite, socketEvents],

	methods: {
		mobileCheck,
		/** Пересчет процента экрана (фикс для Safari) */
		recalcPseudoVh()
		{
			this.pseudoVh = window.document.documentElement.clientHeight / 100;
			this.iosVh = window.innerHeight / 100;
			const doc = document.documentElement;
			doc.style.setProperty('--pseudo-vh', `${this.pseudoVh}px`)
			doc.style.setProperty('--ios-vh', `${this.iosVh}px`)
		},

		updateDeviceParams()
		{
			this.$store.dispatch('ui/updateDeviceParams', {
				width: window.innerWidth,
				height: window.document.documentElement.clientHeight
			});

			document.documentElement.style.setProperty(
				'--pseudo-vh',
				`${this.$store.state.ui.device.realVh}px`)
		},
		checkCorrectAdressFromCookie()
		{
			let adressCookie = this.$cookie.get("address");
			if (adressCookie)
			{
				let adress = adressCookie.split(", ");
				let street = adress[0];
				let house = adress[1];

				if (!house || !street)
				{
					this.$cookie.delete("address");
				}
			}
		},
		// Если человек в течении 5 минут не кликал мышкой и не нажимал клавишу перезагружаем страницу
		resetTimer()
		{
			clearTimeout(this.timeout);
			this.timeout = setTimeout(() =>
			{
				location.reload();
			}, 5 * 60 * 1000); // 5 минут в миллисекундах
		},
		clearTimer()
		{
			clearTimeout(this.timeout);
		},
	},

	beforeMount()
	{
		this.recalcPseudoVh();
		window.addEventListener('resize', this.recalcPseudoVh, {passive: true});
		window.addEventListener('orientationchange', this.recalcPseudoVh, {passive: true});

		// Очищаем таймер отслеживания бездействия
		this.clearTimer();
		document.removeEventListener('click', this.resetTimer);
		document.removeEventListener('keydown', this.resetTimer);
	},
	async mounted()
	{
		this.initJivo();

		//Инициализация сокета
		await this.$socket.init();

		//Запуск отслеживания событий сокета
		await this.handleSocketEvents();

		this.checkCorrectAdressFromCookie();

		// Добавляем таймер отслеживания бездействия
		this.resetTimer();
		document.addEventListener('click', this.resetTimer);
		document.addEventListener('keydown', this.resetTimer);
	},

	created()
	{
		this.updateDeviceParams();
		window.addEventListener('resize', this.updateDeviceParams, {passive: true});
		import('@/assets/fonts/styles.css');
	}
}
</script>
<style lang="scss">
.vue-notification-group
{
	margin-bottom: 20px;
	margin-right: 16px;
}

.app .vue-notification-wrapper
{overflow: visible;}

.my-notification
{
	display: flex;
	align-items: flex-start;
	gap: 8px;

	border-radius: 8px;
	padding: 16px;
	margin-bottom: 8px;

	background: rgba(61, 66, 72, 0.9);
	box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
	backdrop-filter: blur(5px);

	.my-notification__body
	{
		display: flex;
		flex-direction: column;
		flex: 1;
	}

	.my-notification__title
	{
		display: flex;
		align-items: flex-start;
		justify-content: space-between;

		font-family: $mainFontMediumNew;
		font-size: 14px;
		line-height: 20px;
		font-weight: 500;
		color: white;

		& a
		{
			display: flex;
			align-items: center;
			justify-content: center;

			width: 100px;
			height: 30px;
			background-color: $green;
			color: white;
			border-radius: 8px;

			font-size: 12px;
			font-weight: 700;
			line-height: normal;
		}
	}

	.my-notification__close
	{
		cursor: pointer;
		margin-top: -4px;
	}
}

@media (max-width: 767px)
{
	.vue-notification-group
	{
		margin-right: 0;
	}
}
</style>
